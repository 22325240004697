.uppy-Dashboard-inner {
  background: #ffffff !important;
  border: 1px solid $gray-500;

  .uppy-c-btn-primary {
    background: $primary !important;
    border-color: $primary !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    &:hover {
      background: $primary !important;
      box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.4);
    }

    &.no-shadow {
      box-shadow: unset;

      &:hover {
        box-shadow: unset;
      }
    }
  }

  .uppy-DashboardContent-back {
    color: $gray-700 !important;
  }

  .uppy-Dashboard-browse {
    color: $primary;
    &:hover {
      border-bottom: 1px solid $primary;
    }
    &:focus {
      border: none;
    }
  }

  .uppy-DashboardTab-btn {
    &:focus {
      border: none;
    }
  }

  .uppy-StatusBar-progress {
    background-color: $primary;
  }

  .uppy-StatusBar-spinner {
    fill: $primary;
  }

  .uppy-Dashboard-AddFiles {
    border: none !important;
  }
}

.uppy-Root {
  z-index: 1072 !important;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  object-fit: contain;
}
.uppy-c-btn.uppy-Dashboard-Item-action.uppy-Dashboard-Item-action--edit {
  transform: scale(1.5);
  color: $primary;
}
.uppy-Dashboard-Item-previewInnerWrap {
  background-color: $light !important;
}
